@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/fonts.css";

body,
html {
  font-family: "Halyard Display";
}

.App {
  overflow: hidden;
}
